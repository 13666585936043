import styled from '@emotion/styled';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Option, Select} from 'library/components/select';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {useEffect, useState} from 'react';
import {
  getDepositPaymentTypeOption,
  getDepositPaymentTypeOptions,
} from 'slices/policies/lib/helpers';
import {
  selectPolicies,
  setDepositPaymentAmount,
  setDepositPaymentType,
  setIsNeedSaving,
} from 'slices/policies/store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {theme} from 'source/utilities/global-style';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

const InputsWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({isMobile}) => (isMobile ? '100%' : '440px')};
`;

const InputContainer = styled.div<{isMobile: boolean}>`
  width: 100%;
  margin: ${({isMobile}) =>
    isMobile ? '0px 0px 20px 0px' : '0px 20px 0px 0px'};
`;

const SwitchInOutAroundClock = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px;
`;

const SwitchLabel = styled(Text)`
  background: !important;
  margin-right: 18px;
`;

const Label = styled(LabeledComponent)`
  margin-bottom: 20px;
`;

const Deposit = () => {
  const dispatch = useAppDispatch();
  const {directories, policies, isNeedSaving} = useAppSelector(selectPolicies);
  const {security_deposit} = policies;

  const {t} = useTranslation('policies');

  const [isDepositPayment, setIsDepositPayment] = useState<boolean>(
    (security_deposit?.amount ?? 0) > 0,
  );

  const handleSetDefaultDepositType = (type: Option) => {
    dispatch(setDepositPaymentType(type));
  };

  useEffect(() => {
    if (!isNeedSaving) {
      setIsDepositPayment((security_deposit?.amount ?? 0) > 0);
      dispatch(setIsNeedSaving(false));
    }
  }, [isNeedSaving]);

  useEffect(() => {
    if (security_deposit?.amount && security_deposit?.amount > 0) {
      setIsDepositPayment(true);
    }
  }, [security_deposit?.amount]);

  const mobile = useDeviceDetection('mobile');

  return (
    <InputsWrapper isMobile={mobile}>
      <InputContainer isMobile={mobile}>
        <SwitchInOutAroundClock>
          <SwitchLabel size="M">{t('deposit.charge-deposit')}</SwitchLabel>
          <Switch
            initialState={isDepositPayment}
            isActive={isDepositPayment}
            onSwitch={async (enabled) => {
              setIsDepositPayment(enabled);
              if (!enabled) {
                dispatch(setDepositPaymentAmount(0));
              }
            }}
          />
        </SwitchInOutAroundClock>
      </InputContainer>
      {isDepositPayment && (
        <>
          <InputContainer isMobile={mobile}>
            <Label
              size="S"
              gap={11}
              color={theme.palette.fontSecondary}
              text={t('deposit.board-size')}
            >
              <Input
                required
                value={
                  (security_deposit?.amount ?? 0) <= 0
                    ? ''
                    : String(security_deposit?.amount)
                }
                onChange={({target}) =>
                  dispatch(setDepositPaymentAmount(Number(target.value)))
                }
                type="number"
                placeholder={t('input-common.amount', {
                  currency: t('common:units_payments.rub'),
                })}
              />
            </Label>
          </InputContainer>
          <InputContainer isMobile={mobile}>
            <Label
              size="S"
              gap={11}
              color={theme.palette.fontSecondary}
              text={t('deposit.method-payment')}
            >
              <Select
                value={getDepositPaymentTypeOption(
                  directories.depositPaymentTypes ?? [],
                  security_deposit?.type,
                  handleSetDefaultDepositType,
                )}
                options={getDepositPaymentTypeOptions(
                  directories.depositPaymentTypes,
                )}
                setValue={(option) => dispatch(setDepositPaymentType(option))}
              />
            </Label>
          </InputContainer>
        </>
      )}
    </InputsWrapper>
  );
};

export default Deposit;
