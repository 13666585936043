import styled from '@emotion/styled';
import {Question} from 'library/components/hint/question';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import useTranslation from 'next-translate/useTranslation';

import {Link} from 'source/components/link';
import {
  HOTELIERS_KNOWLEDGE_BASE,
  ZHILIBYLI_PHONE,
  isClient,
} from 'source/utilities/guards/environment';
import {UserContext} from 'source/context/user';
import {DEFAULT_HREF} from 'source/utilities/constants/logic';
import {Bitrix} from 'source/services/bitrix';
import {Text} from 'library/components/text';
import {useContext} from 'react';
import {HorizontalDivier} from 'library/components/divider';
import {Button} from 'library/components/button';
import {ButtonLink} from 'library/components/button-link';
import {MenuPopup} from '../ui';

const supportTriggerClassname = 'support-trigger';

const StyledMenuPopup = styled(MenuPopup)`
  &-content {
    width: 650px !important;

    @media (max-width: 700px) {
      width: calc(100% - 20px) !important;
    }
  }
`;

const Trigger = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const UserRow = styled(Row)`
  justify-content: flex-start;
`;

const StyledQuestion = styled(Question)`
  width: 25px;
  height: 25px;
`;

const ChatButton = styled(Button)`
  width: 175px;
  padding: 15px;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const KnowledgeBaseLink = styled(ButtonLink)`
  background-color: ${({theme}) => theme.palette.success};
  border: ${({theme}) => `1px solid ${theme.palette.success}`};
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  width: 175px;
  height: 100%;

  @media (max-width: 550px) {
    width: 100%;
  }

  &:hover {
    border-color: ${({theme}) => theme.palette.fontInvert};
  }
`;

const SupportPhone = styled(Link)`
  font-size: 14px;
  margin-left: 5px;
`;

export const SupportPopup = () => {
  const touch = useDeviceDetection('touch');
  const {t} = useTranslation('components');
  const [user] = useContext(UserContext);

  const handleStartChatClick = () => {
    if (isClient()) {
      const supportTrigger = document.querySelector<HTMLDivElement>(
        `.${supportTriggerClassname}`,
      );

      Bitrix.showBitrixLiveChat();

      if (supportTrigger) {
        supportTrigger.click();
      }
    }
  };

  return (
    <StyledMenuPopup
      trigger={
        <Trigger className={supportTriggerClassname}>
          <StyledQuestion />
        </Trigger>
      }
      closeOnDocumentClick
      arrow={false}
      offsetY={20}
      position="bottom right"
      modal={touch}
    >
      <ContentWrapper>
        <Text size="M">{t('support.title')}</Text>
        <PhoneWrapper>
          <Text size="S">{t('support.phone')}</Text>
          <SupportPhone href={`tel:${ZHILIBYLI_PHONE}`}>
            <Text size="boldS">{`- ${ZHILIBYLI_PHONE}`}</Text>
          </SupportPhone>
        </PhoneWrapper>

        {user?.id ? (
          <UserRow>
            <Text size="S">{t('support.user_id')}</Text>
            <Text size="boldS">{user.id}</Text>
          </UserRow>
        ) : null}

        <Row>
          <Text size="boldS">{t('support.instructions')}</Text>
          <KnowledgeBaseLink
            variant="primary"
            href={HOTELIERS_KNOWLEDGE_BASE || DEFAULT_HREF}
            target="_blank"
          >
            {t('support.knowledge_base')}
          </KnowledgeBaseLink>
        </Row>
        <HorizontalDivier />
        <Row>
          <Text size="boldS">{t('support.answer_on_questions')}</Text>
          <ChatButton onClick={handleStartChatClick}>
            {t('support.start_chat')}
          </ChatButton>
        </Row>
      </ContentWrapper>
    </StyledMenuPopup>
  );
};
