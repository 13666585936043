import {keyframes, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {useEffect, useState} from 'react';
import {ArrowIcon} from 'library/components/arrow-link/arrow';
import {isClient} from 'source/utilities/guards/environment';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {selectIsBitrixSetup} from 'slices/bitrix/store';
import {useAppSelector} from 'source/store';

const bitrixButtonClassName = '.b24-widget-button-shadow';
const bitrixButtonWrapperClassName = '.b24-widget-button-wrapper';
const bitrixButtonOpenedClassName = 'b24-widget-button-show';
const SHOW_BUTTON_PAGE_OFFSET = 200;

const widgetPulse = keyframes`
  50% {
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2,2);
    transform: scale(2,2);
    opacity: 0;
  }
`;

const Wrapper = styled.div<{show: boolean; isTouch: boolean}>`
  position: fixed;
  bottom: ${({isTouch}) => (isTouch ? '55px' : '125px')};
  right: ${({isTouch}) => (isTouch ? '5px' : '45px')};
  width: ${({isTouch}) => (isTouch ? '40px' : '66px')};
  height: ${({isTouch}) => (isTouch ? '40px' : '66px')};
  border-radius: 50%;
  z-index: 10150;
  transition: all 0.2s ease-in-out;
  visibility: ${({show}) => (show ? 'visible' : 'hidden')};
  opacity: ${({show}) => (show ? '1' : '0')};
  display: 'flex';
  justify-content: center;
  align-items: center;
`;

const ButtonMask = styled.div<{isTouch: boolean}>`
  position: absolute;
  top: ${({isTouch}) => (isTouch ? '-5px' : '-8px')};
  left: ${({isTouch}) => (isTouch ? '-5px' : '-8px')};
  height: ${({isTouch}) => (isTouch ? '50px' : '71px')};
  width: ${({isTouch}) => (isTouch ? '50px' : '71px')};
  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.scrollTopButton};
  opacity: 0.15;
`;

const Button = styled.button<{isTouch: boolean}>`
  position: relative;
  overflow: visible;
  width: ${({isTouch}) => (isTouch ? '40px' : '56px')};
  height: ${({isTouch}) => (isTouch ? '40px' : '56px')};
  border-radius: 100%;
  background-color: ${({theme}) => theme.palette.scrollTopButton};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Pulse = styled.div<{isTouch: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: ${({theme}) => `1px solid ${theme.palette.scrollTopButton}`};
  border-radius: 50%;
  animation: ${widgetPulse} infinite 1.5s;
`;

const StyledArrowIcon = styled(ArrowIcon)<{isTouch: boolean}>`
  width: ${({isTouch}) => (isTouch ? '16px' : '22px')};
  height: ${({isTouch}) => (isTouch ? '16px' : '22px')};
  transform: rotate(180deg);
`;

export const ScrollTopButton = () => {
  const theme = useTheme();
  const isBitrixSetup = useAppSelector(selectIsBitrixSetup);
  const isTouch = useDeviceDetection('touch');

  const [showTopButton, setShowTopButton] = useState(false);

  const handleScrollTop = () => {
    if (isClient()) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  const toggleVisibility = () => {
    if (isClient()) {
      // Появление кнопки только если меню битрикса закрыто
      const bitrixButton = document.querySelector(bitrixButtonClassName);

      const isBitrixOpened = Boolean(
        bitrixButton?.classList.contains(bitrixButtonOpenedClassName),
      );

      if (!isBitrixOpened) {
        setShowTopButton(window.scrollY > SHOW_BUTTON_PAGE_OFFSET);
      }
    }
  };

  useEffect(() => {
    // Показывает кнопку только если скролл Y > 200
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  useEffect(() => {
    if (!isBitrixSetup) {
      return;
    }

    // скрытие кнопки битрикса на тач устройствах
    const bitrixButtonWrapper = document.querySelector<HTMLElement>(
      bitrixButtonWrapperClassName,
    );

    if (isTouch && bitrixButtonWrapper) {
      bitrixButtonWrapper.style.display = 'none';
      return;
    }

    const bitrixButton = document.querySelector(bitrixButtonClassName);

    // Отслеживает открыто ли меню битрикса и скрывает кнопку скролла, если да
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const isBitrixOpened = Boolean(
            bitrixButton?.classList.contains(bitrixButtonOpenedClassName),
          );

          if (window.scrollY > SHOW_BUTTON_PAGE_OFFSET) {
            setShowTopButton(!isBitrixOpened);
          }
        }
      });
    });

    if (bitrixButton) {
      observer.observe(bitrixButton, {attributes: true});
    }

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [isBitrixSetup, isTouch]);

  return (
    <Wrapper show={showTopButton} isTouch={isTouch}>
      <Button onClick={handleScrollTop} isTouch={isTouch}>
        <ButtonMask isTouch={isTouch} />
        <Pulse isTouch={isTouch} />
        <StyledArrowIcon color={theme.palette.fontAccent} isTouch={isTouch} />
      </Button>
    </Wrapper>
  );
};
