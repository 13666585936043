import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import {HotelContext} from 'source/context/hotel';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {parseBooleanQueryParameter} from 'source/utilities/network/router';

import {
  selectFacilities,
  selectRawFacilities,
  selectServices,
  setServices,
  setFacilities as setStoreFacilities,
} from 'slices/facilities/store';

import {useTravelLineIntegration} from 'source/utilities/hooks/use-is-travel-line-integration';
import TravelLineProvider from 'source/components/travel-line-provider';
import {createService, editService, deleteService} from './network';
import {
  facilityFilter,
  facilityGroupFilter,
  getHotelFacilities,
  updateToggledFacility,
} from './lib/helpers';
import {
  FacilitiesState,
  FacilitiesStateFacility,
  FacilitiesStateGroup,
} from './lib/types';
import Services from './ui/services';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const HeaderSubtitle = styled(Text)`
  width: fit-content;
  padding: 20px;
  letter-spacing: 0.03em;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(220, 220, 220, 0.4);
`;

const SearchWrapper = styled.div`
  max-width: 440px;
  margin: 20px 0px;
`;

const ServicesWrapper = styled.div`
  margin: 20px 0px;
`;

const FacilityWrapper = styled.div`
  margin-bottom: 15px;
`;

const FacilityHeader = styled(Text)`
  margin-bottom: 5px;
`;

const Facility = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border-bottom: 1px solid ${({theme}) => theme.palette.activeBackground};
  padding: 15px 10px 15px 10px;
  transition: 0.3s background-color ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.palette.hoverBackground_08};
    border-radius: 3px;
  }
`;

const Content = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {t} = useTranslation('facilities');
  const mobile = useDeviceDetection('mobile');
  const services = useAppSelector(selectServices);
  const router = useRouter();
  const isAddService = parseBooleanQueryParameter(router.query.isAddService);
  const [hotel] = useContext(HotelContext);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isServicePopupOpen, setIsServicePopupOpen] = useState(false);
  const [hasTravelLineIntegration] = useTravelLineIntegration();

  const hotelFacilities = useAppSelector(selectFacilities);

  const rawFacilities = useAppSelector(selectRawFacilities);

  const [facilities, setFacilities] = useState<FacilitiesState>(() =>
    getHotelFacilities(hotelFacilities, rawFacilities),
  );

  const onSwitchComplete = (facility: FacilitiesStateFacility) => {
    setFacilities((previous) => {
      const updatedFacilities = updateToggledFacility(previous, facility);

      const storeFacility = updatedFacilities
        .flatMap((facility) => facility.facilities)
        .map((facility) => ({
          facility_id: facility.id,
          enabled: facility.enabled,
        })) as HotelFacilities;

      dispatch(setStoreFacilities(storeFacility));

      return updatedFacilities;
    });
  };

  const onFacilitySwitch = async (facility: FacilitiesStateFacility) => {
    if (hotel?.id) {
      onSwitchComplete(facility);
    }
  };

  const handleCreateService = async (service: ClientHotelService) => {
    if (hotel?.id) {
      setLoading(true);
      createService(
        hotel.id,
        service,
        (data) => {
          if (data) {
            dispatch(setServices([data, ...services]));
          }
        },
        setLoading,
        setIsServicePopupOpen,
      );
    }
  };

  const handleEditService = async (
    serviceId: number,
    service: ClientHotelService,
  ) => {
    if (hotel?.id && services) {
      setLoading(true);
      editService(
        hotel.id,
        serviceId,
        service,
        (data) => {
          if (data) {
            const updatedServices = services.map((item) =>
              item.id === serviceId ? data : item,
            );

            dispatch(setServices(updatedServices));
          }
        },
        setLoading,
        setIsServicePopupOpen,
      );
    }
  };

  const handleDeleteService = async (service: HotelService) => {
    if (hotel?.id && services) {
      setLoading(true);

      deleteService(
        hotel.id,
        service.id,
        () => {
          const filteredServices = services.filter(
            (item) => item.id !== service.id,
          );

          dispatch(setServices(filteredServices));
        },
        setLoading,
        setIsServicePopupOpen,
      );
    }
  };

  useEffect(() => {
    setFacilities(getHotelFacilities(hotelFacilities, rawFacilities));
  }, [hotelFacilities]);

  useEffect(() => {
    if (isAddService) {
      setIsServicePopupOpen(true);
    }
  }, []);

  return (
    <Wrapper>
      <HeaderSubtitle size="S">{t('subtitle')}</HeaderSubtitle>
      <ServicesWrapper>
        {!hasTravelLineIntegration && (
          <Services
            onCreate={handleCreateService}
            onEdit={handleEditService}
            onDelete={handleDeleteService}
            loading={loading}
            open={isServicePopupOpen}
            setOpen={setIsServicePopupOpen}
          />
        )}
      </ServicesWrapper>

      <TravelLineProvider>
        <Text size={mobile ? 'boldS' : 'boldL'}>{t('facilities')}</Text>
        <SearchWrapper>
          <LabeledComponent
            color={theme.palette.fontSecondary}
            size="XS"
            text={t('facilities_search')}
            gap={10}
          >
            <Input
              label={null}
              placeholder={t('type_to_find_facilities')}
              autoComplete="off"
                type="text"
              value={searchText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchText(event.target.value);
              }}
              onCrossClick={() => setSearchText('')}
            />
          </LabeledComponent>
        </SearchWrapper>
        {facilityGroupFilter(facilities, searchText).map(
          (group: FacilitiesStateGroup) => (
            <FacilityWrapper key={group.id}>
              <FacilityHeader size="M">{group.name}</FacilityHeader>
              {facilityFilter(group, searchText).map(
                (facility: FacilitiesStateFacility) => (
                  <div key={facility.id}>
                    <Facility>
                      <Text size="S">{facility.name}</Text>
                      <Switch
                        initialState={facility.enabled}
                        onSwitch={() => onFacilitySwitch(facility)}
                        isActive={facility.enabled}
                      />
                    </Facility>
                  </div>
                ),
              )}
            </FacilityWrapper>
          ),
        )}
      </TravelLineProvider>
    </Wrapper>
  );
};

export default Content;
