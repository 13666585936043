import styled from '@emotion/styled';
import Link from 'next/link';
import {MouseEvent, MouseEventHandler, useContext, useState} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {HotelContext} from 'source/context/hotel';
import {getViewMonthsNameFromBackendDate} from 'source/utilities/dates';
import {useLocalStore} from 'source/context/localstorage-store';
import {useAppDispatch, useAppSelector} from 'source/store';
import {formatCancellation} from 'source/utilities/business';
import {showSuccessMessage} from 'source/utilities/exceptions/business';
import {BinIcon} from 'library/icons/bin-icon';
import {ActionModal} from 'library/components/action-modal';
import {Button} from 'library/components/button';
import {Switch} from 'library/components/switch';
import {PencilIcon} from 'library/icons/pencil-icon';
import {SubText} from 'library/components/text';
import {
  RowBody,
  RowBodyData,
  RowHead,
  RowHeadData,
  TableBoilerplate,
} from 'library/components/table';

import {getIncludedTypes} from 'slices/facilities';
import {ECancellationRateType} from 'slices/rate';
import {handleRateDelete, handleRateSwitch} from 'slices/rates/network';
import {selectRates, setRateState, removeByRateId} from 'slices/rates/store';
import {TableRate} from 'slices/rates/lib/types';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';
import {buildEditRateRoute} from 'source/utilities/url';
import {useTravelLineIntegration} from 'source/utilities/hooks/use-is-travel-line-integration';

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  border-top: 1px solid ${({theme}) => theme.palette.border};
  margin-top: 21px;
`;

const LinkTitle = styled(Link)`
  display: block;
  text-decoration: none;
  min-width: max-content;
  color: ${({theme}) => theme.palette.fontDefault};
`;

const PeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SwitchRate = styled(Switch)``;

const EditRate = styled(PencilIcon)`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.border};
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  &:hover {
    background-color: ${({theme}) => theme.palette.fontPrimary};
    path {
      fill: ${({theme}) => theme.palette.defaultBackground};
      stroke: ${({theme}) => theme.palette.defaultBackground};
    }
  }
`;

const DeleteButtonRate = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.palette.border};
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.palette.fontPrimary};
    path {
      fill: ${({theme}) => theme.palette.defaultBackground};
    }
  }
`;

const DeleteRate = styled(BinIcon)`
  width: 100%;
  height: 100%;
`;

const EditActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const RateActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
`;

const StyledRowBodyData = styled(RowBodyData)`
  font-size: 14px;
`;

const RateName = styled.span`
  max-width: 550px;
  text-wrap: wrap;

  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledRowHead = styled(RowHead)`
  font-size: 12px;
`;

const RatesTable = () => {
  const {t} = useTranslation('rates');
  const [hotel] = useContext(HotelContext);
  const [localStore] = useLocalStore();
  const [hasRealtyIntegration] = useRealtyIntegration();
  const [hasTravelLineIntegration] = useTravelLineIntegration();

  const [rateToDelete, setRateToDelete] = useState<TableRate | null>(null);
  const rates = useAppSelector(selectRates);
  const dispatch = useAppDispatch();

  const handleUpdateRateState = async (id: number, enabled: boolean) => {
    if (hotel && hotel.id && id) {
      await handleRateSwitch(hotel.id, id, Boolean(enabled), (value) => {
        dispatch(setRateState({id, enabled: value}));
      });
    }
  };

  const handleDelete = (
    event: MouseEvent<HTMLButtonElement>,
    close?: MouseEventHandler<HTMLButtonElement>,
  ) => {
    if (!rateToDelete) {
      return;
    }
    const {name, id} = rateToDelete;
    if (hotel && hotel.id && id) {
      handleRateDelete(hotel.id, id, () => {
        dispatch(removeByRateId(id));
        showSuccessMessage(t('notify.delete', {name}));
        close?.(event);
        setRateToDelete(null);
      });
    }
  };

  return (
    <TableWrapper>
      <TableBoilerplate
        rowHeadChildren={
          <StyledRowHead>
            <RowHeadData isFirst>{t('tariff')}</RowHeadData>
            <RowHeadData>{t('meal')}</RowHeadData>
            <RowHeadData>{t('cancellation_penalty')}</RowHeadData>
            <RowHeadData>{t('validity_period')}</RowHeadData>
            <RowHeadData>
              {t('discount')}/{t('surcharge')}
            </RowHeadData>
            <RowHeadData>{t('calculated_from')}</RowHeadData>
            <RowHeadData isLast>{t('rate_actions')}</RowHeadData>
          </StyledRowHead>
        }
        rowBodyChildren={
          <>
            {rates
              .filter((rate) =>
                localStore.hasShowDisableRate ? true : rate.enabled,
              )
              .map((rate) => {
                if (!hotel) {
                  return null;
                }

                const {
                  id,
                  enabled,
                  cancellation_type,
                  cancellation_fine,
                  meal,
                  period,
                } = rate;
                const includedMeal = meal?.include_types;
                const hasAnyPeriodDate = period?.from || period?.to;
                const fineType =
                  cancellation_type?.value ??
                  ECancellationRateType.WithoutFineForNoShow;
                const isFlexibleRules =
                  cancellation_fine &&
                  fineType === ECancellationRateType.FlexibleRules;

                return (
                  <RowBody key={id}>
                    <StyledRowBodyData isFirst>
                      <LinkTitle href={buildEditRateRoute(hotel?.id, id)}>
                        <EditActionWrapper>
                          <EditRate />
                          {!hasRealtyIntegration && (
                            <DeleteButtonRate
                              disabled={hasTravelLineIntegration}
                              buttonType="secondary"
                              onClick={(event) => {
                                event.preventDefault();
                                setRateToDelete(rate);
                              }}
                            >
                              <DeleteRate />
                            </DeleteButtonRate>
                          )}

                          <RateName>{rate.name}</RateName>
                        </EditActionWrapper>
                      </LinkTitle>
                    </StyledRowBodyData>
                    <StyledRowBodyData>
                      {getIncludedTypes(t('no_meal'), includedMeal)}
                    </StyledRowBodyData>
                    <StyledRowBodyData>
                      {!cancellation_fine && cancellation_type && (
                        <div>{cancellation_type.title}</div>
                      )}
                      {isFlexibleRules && (
                        <>
                          <div>{`${cancellation_fine?.days} ${t('days')}`}</div>
                          <div>
                            {formatCancellation(cancellation_fine, t, true)}
                          </div>
                        </>
                      )}
                    </StyledRowBodyData>
                    <StyledRowBodyData>
                      {hasAnyPeriodDate ? (
                        <PeriodWrapper>
                          {period?.from && (
                            <SubText size="XS">
                              {t('period_from', {
                                date: getViewMonthsNameFromBackendDate(
                                  period.from,
                                ),
                              })}
                            </SubText>
                          )}
                          {period?.to && (
                            <SubText size="XS">
                              {t('period_to', {
                                date: getViewMonthsNameFromBackendDate(
                                  period.to,
                                ),
                              })}
                            </SubText>
                          )}
                        </PeriodWrapper>
                      ) : (
                        t('unlimited')
                      )}
                    </StyledRowBodyData>
                    <StyledRowBodyData>0%</StyledRowBodyData>
                    <StyledRowBodyData hideBorder />
                    <StyledRowBodyData isLast>
                      <RateActions>
                        <SwitchRate
                          onSwitch={async () => {
                            if (id && enabled !== undefined) {
                              await handleUpdateRateState(id, enabled);
                            }
                          }}
                          initialState={enabled ?? false}
                          isActive={enabled}
                          disabled={
                            hasRealtyIntegration || hasTravelLineIntegration
                          }
                        />
                      </RateActions>
                    </StyledRowBodyData>
                  </RowBody>
                );
              })}
          </>
        }
      />

      <ActionModal
        maxWidth={440}
        open={Boolean(rateToDelete)}
        title={t('want_to_delete_rate', {name: rateToDelete?.name})}
        action={(close, event) => handleDelete(event, close)}
        onClose={() => {
          setRateToDelete(null);
        }}
        actionText={t('components:delete')}
      />
    </TableWrapper>
  );
};

export default RatesTable;
