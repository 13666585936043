import {all, has, is, __} from 'ramda';

export const isUser = (value: any): value is User =>
  all(has(__, value), [
    'id',
    'first_name',
    'last_name',
    'email',
    'phone',
    'api_token',
  ]);

export const isValidHotelId = (value: any): value is Hotel => is(Number, value);

export const isMyHotels = (value: any): value is MyHotel[] => is(Array, value);
