
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Global, ThemeProvider } from '@emotion/react';
import { YMaps } from '@pbe/react-yandex-maps';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { ru } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import type { AppProps } from 'next/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import NextNProgress from 'nextjs-progressbar';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import { BitrixScript } from 'source/components/bitrix-script';
import { Footer } from 'source/components/footer';
import { GoogleTagManagerScript } from 'source/components/google-tag-manager-script';
import { Header } from 'source/components/header';
import { HotjarScript } from 'source/components/hotjar';
import { LoadingModal } from 'source/components/loading-modal';
import { RoistatScript } from 'source/components/roistat';
import { ScrollTopButton } from 'source/components/scroll-top-button';
import { YandexMetrikaScript } from 'source/components/yandex-metrika-script';
import { AuthorizationProvider } from 'source/context/authorization';
import { BalanceProvider } from 'source/context/balance';
import { CalendarRefProvider } from 'source/context/calendar-ref';
import { HotelProvider } from 'source/context/hotel';
import { LoadingProvider } from 'source/context/loading';
import { LocalStoreProvider } from 'source/context/localstorage-store';
import { RequisitesProvider } from 'source/context/requisites';
import { SessionStoreProvider } from 'source/context/session-store';
import { UserProvider } from 'source/context/user';
import { UtilitiesProvider } from 'source/context/utilities';
import { store } from 'source/store';
import { palette, styles, theme } from 'source/utilities/global-style';
import { YANDEX_API_KEY } from 'source/utilities/guards/environment';
import { ApplicationProperties } from 'source/utilities/ui';
setDefaultOptions({ locale: ru });
const Application = ({ Component, pageProps, }: AppProps<ApplicationProperties>) => {
    return (<Provider store={store}>
      <ThemeProvider theme={theme}>
        <NextNProgress color={palette.fontPrimary} startPosition={0.3} stopDelayMs={200} height={3} showOnShallow/>
        <LocalStoreProvider>
          <SessionStoreProvider>
            <UtilitiesProvider serverValue={pageProps.utilities}>
              <AuthorizationProvider serverValue={pageProps.authorized}>
                <LoadingProvider serverValue={false}>
                  <UserProvider serverValue={pageProps.user}>
                    <HotelProvider>
                      <CalendarRefProvider>
                        <RequisitesProvider>
                          <BitrixScript />
                          <YandexMetrikaScript />
                          <GoogleTagManagerScript />
                          <RoistatScript />
                          <HotjarScript />
                          <YMaps query={{
            apikey: YANDEX_API_KEY,
            lang: 'ru_RU',
        }}>
                            <Global styles={styles}/>
                            <BalanceProvider serverValue={pageProps.user?.balance}/>
                            <LoadingModal />
                            <ScrollTopButton />
                            <Header />

                            <Component {...pageProps}/>

                            <Footer />

                            <ToastContainer position="bottom-left"/>
                          </YMaps>
                        </RequisitesProvider>
                      </CalendarRefProvider>
                    </HotelProvider>
                  </UserProvider>
                </LoadingProvider>
              </AuthorizationProvider>
            </UtilitiesProvider>
          </SessionStoreProvider>
        </LocalStoreProvider>
      </ThemeProvider>
    </Provider>);
};
const __Next_Translate__Page__19276122be7__ = Application;

    export default __appWithI18n(__Next_Translate__Page__19276122be7__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  